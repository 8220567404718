<template>
  <BasePage :isLoaded="isLoaded" id="js-webinars-top">
    <template v-slot:content>
      <v-container>
        <v-row class="mb-16 pt-16">
          <v-col
            cols="12"
            md="10"
            offset-md="1"
            lg="9"
            offset-lg="2"
            >
            <h1 class="-sci-gradient"><span>{{Webinars.webinarsTitle}}</span></h1>
            <p>{{Webinars.webinarsDescription}}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="10"
            offset-md="1"
            lg="9"
            offset-lg="2"
          >
            <Webinar
              ref="bigWebinar"
              v-bind="ListWebinar.items[0]"
              @playing="onPlay(0)"
              @pause="onPause(0)"
              @ended="onEnd(0)"
              big
            />
          </v-col>
        </v-row>
      </v-container>
      <TitleAndText
        :title="Webinars.webinarsPastVideoTitle"
        :text="Webinars.webinarsPastVideoDescription"
      />
      <v-container class="pb-16" >
        <v-row>
          <v-col
            cols="12"
            md="10"
            offset-md="1"
            lg="9"
            offset-lg="2"
          >
            <transition-group name="flip-list" tag="div" class="row">
              <template v-for="(webinar, i) in ListWebinar.items">
                <v-col
                  cols="12"
                  md="6"
                  v-if="i !== 0"
                  :key="i"
                  class="flip-list-item mb-6"
                >
                  <Webinar
                    v-bind="webinar"
                    ref="webinars"
                    @playing="onPlay(i)"
                    @pause="onPause(i)"
                    @ended="onEnd(i)"
                  />
                </v-col>
              </template>
            </transition-group>
          </v-col>
        </v-row>
        <v-row align="center" class="buttons-row">
          <v-col cols="3" offset-sm="1">
            <ButtonScrollTo target="js-webinars-top" />
          </v-col>
          <v-col cols="6" sm="4">
            <ButtonLoadMore
              v-if="ListWebinar.cursor"
              label="Load More"
              @click="loadMoreData"
              :disabled="isBtnDisabled"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasePage>
</template>

<script>
import ListWebinar from '@/graphql/ListWebinar.gql'
import GetWebinars from '@/graphql/GetWebinars.gql'
import BasePage from '@/organisms/BasePage'
import TitleAndText from '@/molecules/TitleAndText'
import Webinar from '@/molecules/Webinar.vue'
import ButtonLoadMore from '@/atoms/ButtonLoadMore.vue'
import ButtonScrollTo from '@/atoms/ButtonScrollTo.vue'

export default {
  name: 'WebinarPage',

  components: {
    BasePage,
    Webinar,
    TitleAndText,
    ButtonLoadMore,
    ButtonScrollTo
  },
  apollo: {
    Webinars: {
      query: GetWebinars,
      variables: {
        slug: 'globals'
      }
    },
    ListWebinar: {
      query: ListWebinar,
      variables: {
        limit: 7
      }
    }
  },
  data () {
    return {
      webinars: null
    }
  },
  computed: {
    isLoaded () {
      return !!(this.Webinars && this.ListWebinar && this.ListWebinar.items)
    },
    isBtnDisabled () {
      return this.$apollo.queries.ListWebinar.loading
    }
  },
  methods: {
    onPlay (i) {
      if (!this.webinars) {
        const webinars = this.$refs.webinars

        webinars.unshift(this.$refs.bigWebinar)
        this.webinars = webinars
      }

      if (Number.isFinite(this.isPlaying)) {
        this.webinars[this.isPlaying].pause()
      }
      this.isPlaying = i
    },
    onPause (i) {
      if (this.isPlaying === i) {
        this.isPlaying = false
      }
    },
    onEnd (i) {
      if (this.isPlaying === i) {
        this.isPlaying = false
      }
    },
    loadMoreData () {
      if (this.ListWebinar.cursor) {
        this.$apollo.queries.ListWebinar.fetchMore({
          variables: {
            cursor: this.ListWebinar.cursor
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              ListWebinar: {
                __typename: previousResult.ListWebinar.__typename,
                items: [...previousResult.ListWebinar.items, ...fetchMoreResult.ListWebinar.items],
                totalCount: previousResult.ListWebinar.totalCount,
                cursor: fetchMoreResult.ListWebinar.cursor
              }
            }
          }
        })
      }
    }
  },

  metaInfo: function () {
    if (this.Webinars) {
      return {
        title: this.Webinars.webinarsTitle,
        meta: [
          { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.Webinars.webinarsTitle },
          { vmid: 'description', name: 'description', content: this.Webinars.webinarsDescription },
          { vmid: 'ogdescription', property: 'og:description', content: this.Webinars.webinarsDescription }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.flip-list-item {
  transition: all 0.5s;
}

.flip-list-enter,
.flip-list-leave-to {
  opacity: 0;
}

.flip-list-leave-active {
  position: absolute;
}
</style>
