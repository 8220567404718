<template>
  <article>
    <article>
      <VideoPlayer
        ref="video"
        v-if="!!(videoExternal || videoFile)"
        :class="{'mb-4': big, 'mb-2': !big}"
        :video="(videoExternal || videoFile)"
        :poster="videoFilePoster ? videoFilePoster.id : null"
        v-on="$listeners"
      />
      <ul>
        <li v-for="(tag,i) in tags" :key="i">
          #{{tag}}
        </li>
      </ul>
      <h2 class="h3" v-if="big">{{title}}</h2>
      <h3 class="h4" v-else>{{title}}</h3>
    </article>
  </article>
</template>

<script>

import VideoPlayer from '@/molecules/VideoPlayer.vue'
export default {
  name: 'Webinar',
  props: {
    title: { type: String },
    tags: { type: Array },
    videoExternal: { type: String },
    videoFile: { type: Object },
    videoFilePoster: { type: Object },
    big: { type: Boolean }
  },
  components: { VideoPlayer },
  methods: {
    pause () {
      this.$refs.video.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
}

li {
  font-family: 'PP Woodland Regular';
  display: inline-block;
  list-style: none;
  margin-right: rem(8px);

  font-size: rem(16px);
  line-height: rem(16px);
  color: $color-blue-2;
}
</style>
